var $ = require("jquery");
import Swal from 'sweetalert2';
const InitSwal = require('sweetalert2');
const axios = require('axios');
import MicroModal from 'micromodal';

function _notification(message, status, progres = true) {
    const Toast = InitSwal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: progres,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', InitSwal.stopTimer)
            toast.addEventListener('mouseleave', InitSwal.resumeTimer)
        }
    })

    Toast.fire({
        icon: status,
        title: message
    })
}

axios.get('/policy-notificatin/display')
.then(function(response) {
    var data = response.data
    $("#policy-modal-content").html(data.message)
    $("#not-title").html(data.title)
    $("#policyId").val(data.policyId)
    $("#cashoutBtn").html(data.cashout_button)
    initModal()

    if (!data.completion) {
        $('.note-policy').click()
    }
});

$("#relationship_select").change(function(){
    if ($(this).val() == "Other") {
        $("#relation_ship_contact").show()
        $("#emergency_contact_person_others").show()
    }else{
        $("#relation_ship_contact").hide()
        $("#emergency_contact_person_others").hide()

        $("#relation_ship_contact_val").val("")
    }
})

//Update Relationship
$("#relationship_update").click(function(){
    if($("#home_address").val() == ''){
        return _notification("Enter Valide Address.", 'warning', false)
    }

    if($("#emergency_contact_person").val() == ''){
        return _notification("Select Relationship with Contact Person.", 'warning', false)
    }

    if($("#emergency_contact").val() == ''){
        return _notification("Enter Relationship with Contact Number.", 'warning', false)
    }

    axios.post('/profile/update/relationship', {
        home_address: $("#home_address").val(), //varEmail is a variable which holds the email
        emergency_contact_person: $("#emergency_contact_person").val(),
        emergency_contact: $("#emergency_contact").val()
    })
    .then(function(response) {
        //...
        var data = response.data.data

        if (response.data.status) {

            _notification(response.data.message, 'success', false)


            return;
        }

        _notification(response.data.message, 'warning')

    });
})

/*****
 *
 * Home Card Data
 */
function initModal(params) {
    try {
    
        MicroModal.init({
          awaitCloseAnimation: true,// set to false, to remove close animation
          disableFocus: true,
          onShow: function(modal) {
            console.log("micromodal open");
          },
          onClose: function(modal) {
            console.log("micromodal close");
          }
        });
        
      } catch (e) {
        console.log("micromodal error: ", e);
      }
}

if (location.pathname == '/home') {

    axios.get('/home/data')
    .then(function(response) {

        var data = response.data
        $("#earning_sales").html(data.earning_sales)
        $("#earning_unpaid").html(data.earning_unpaid)
        $("#earning_renewal").html(data.earning_renewal)
        $("#earning_transaction").html(data.earning_transaction)
        $("#earning_total").html(data.earning_total)

        $("#customer_created_today").html(data.customer_created_today)
        $("#customer_active").html(data.customer_active)
        $("#customer_inactive").html(data.customer_inactive)
        $("#customer_total").html(data.customer_total)

        $("#commision_paid").html(data.commision_paid)
        $("#performance_bonus").html(data.performance_bonus)
        $("#commision_unpaid").html(data.commision_unpaid)
        $("#commision_total").html(data.commision_total)

        $("#performace_sales").html(data.performace_sales)
        $("#performace_satisfactory").html(data.performace_satisfactory)
        $("#performace_rentention").html(data.performace_rentention)

        $("#balance").html(data.balance)

        if (data.review !== undefined && data.review !== null && data.review !== '') {
            $("#BO").html(data.review.name);
            $("#bo-id").val(data.review.business_id);
            $('[data-micromodal-trigger="filter-modal"]').click();
        }
        
    });

    $("#withdrawal").click(function () {    
        // Set the unique request ID in the headers
        var headers = {
            'X-Request-ID': $("#idem").val()
        };
        if ($('#transfer_amount').val() == '' || $('#transfer_amount').val() == 0) {
            return
        }
        $(this).html("Processing...");
        axios.post('/commsion/withdrawal/request',{
            'X-Request-ID': $("#idem").val(),
            amount:$("#transfer_amount").val()
        })
        .then(function(response) {
            var data = response.data.data;
            if (response.data.status) {
                _notification(response.data.message, "success", false);
                location.reload();
                return;
            }

            $("#withdrawal").html("Withdraw");
            _notification(response.data.message, "warning", false);
            return;
        });
    });

    $("#review").click(function () {    
        _notification("Thank you for successfully onboarding. ", "success", false);
        axios.post('/submit-review',{
            'business_id':$("#bo-id").val()
        })
        .then(function(response) {
            var data = response.data.data;
            if (response.data.status) {
                location.reload();
                return;
            }
        });
    });
    
    axios.get('/home/graph')
    .then(function(response) {
        var data = response.data
        plot_graph(data.sums, data.dates);
    });
}

/*****
 *
 * Customer Card Data
 */

if (location.pathname == '/onboard/onboard') {

}

$("#verify_acount").on('click',function(){

    if($("#account_number").val() == ''){
        return _notification("Enter Valide Account No.", 'warning', false)
    }

    if($("#bank").val() == ''){
        return _notification("Select your Bank.", 'warning', false)
    }

    if($("#bvn").val() == ''){
        return _notification("Enter BVN.", 'warning', false)
    }

    _notification("Setting up Account...", 'success')
    axios.post('/account/verification', {
        'account_number':$("#account_number").val(),
        'account_bank':$("#bank").val(),
        'bvn':$("#bvn").val()
    })
    .then(function(response) {
        //...
        var data = response.data.data

        if (response.data.status) {
            $("#account_name").val(data.responseBody.accountName)
            _notification(response.data.message, 'success', false)
            $("#onboard").show()
            $("#disval").show()

            return;
        }
        $("#onboard").hide()
        _notification(response.data.message, 'warning')

    });
})

$("#onboard").click(function(){
    if($("#account_number").val() == ''){
        return _notification("Enter Valide Account No.", 'warning', false)
    }
    if($("#bank").val() == ''){
        return _notification("Select your Bank.", 'warning', false)
    }
    _notification("Validating Account...", 'success')
    axios.post('/account/verification/complete', {
        bank_account_number: $("#account_number").val(), //varEmail is a variable which holds the email
        bank_code: $("#bank").val(),
        bank_account_name:$("#bank option:selected").text(),
        bvn:$("#bvn").val()

    })
    .then(function(response) {
        var data = response.data.data
        if (response.data.status) {
            _notification(response.data.message, 'success', false)
            location.replace('/home')
            return;
        }
        $("#disbtn").hide()
        $("#disval").hide()
        _notification(response.data.message, 'warning')
    });
})

$("#board_business").click(function(){

    if($("#first_name").val() == ''){
        return _notification("Enter Business owner Fullname.", 'warning', false)
    }

    if($("#state").val() == ''){
        return _notification("Enter Business State HQ.", 'warning', false)
    }

    if($("#city").val() == ''){
        return _notification("Enter Business City", 'warning', false)
    }

    if($("#landmark").val() == ''){
        return _notification("Enter Business Address.", 'warning', false)
    }

    if($("#username").val() == ''){
        return _notification("Enter Business Owner Username.", 'warning', false)
    }

    if($("#name").val() == ''){
        return _notification("Enter Business Name", 'warning', false)
    }

    if($("#email").val() == ''){
        return _notification("Businness Email.", 'warning', false)
    }

    if($("#business_type").val() == ''){
        return _notification("Select Businness Type.", 'warning', false)
    }

    if($("#contact_no").val() == ''){
        return _notification("Enter Businness Contact No.", 'warning', false)
    }

    if($("#password").val() == ''){
        return _notification("Enter Password", 'warning', false)
    }

    if($("#percentage").val() == ''){
        return _notification("Select Lead.", 'warning', false)
    }
    $("#board_business").html("Boarding Business...")
    $("#board_business").prop("disabled",true);

    axios.post('/onboard/business', {
        first_name: $("#first_name").val(),
        state: $("#state").val(),
        city: $("#city").val(),
        landmark: $("#landmark").val(),
        username: $("#username").val(),
        zip_code: $("#zip_code").val(),
        name: $("#name").val(),
        country: $("#country").val(),
        email: $("#email").val(),
        password: $("#password").val(),
        contact_no: $("#contact_no").val(),
        business_type: $("#business_type").val(),
        percentage:$("#percentage").val(),
    })
    .then(function(response) {
        //...
        var data = response.data.data
        if (response.data.status) {
            _notification(response.data.message, 'success', false)
            location.replace('/business/detail/'+response.data.businessId)
            return;
        }

        _notification(response.data.message, 'warning')

        $("#board_business").html("Add")
        $("#board_business").prop("disabled",false);

    });
})

$("#username").change(function(){
    if ($(this).val() == '') {
        return;
    }
    axios.post('/onboard/username/verification', {
        username: $("#username").val()
    })
    .then(function(response) {
        var data = response.data.data
        if (response.data.status) {
            return;
        }
        $("#username").val("")
        _notification(response.data.message, 'warning', false)
    });
})

$("#board-business").click(function (){
    location.replace('/onboard/business')
})

$(document).ready(function() {
    var inputElement = $('#transfer_amount');
    inputElement.on('input', function() {
        getTransactionRate();
    });
});

//if (location.pathname == '/onboard/business') {
$(document).on('change', '#country', function() {
    get_states();
});
$(document).ready(function() {
    get_states();
});
//}

$(document).on('click', '.update_account_number', function() {
    if ($('#bank_account_number').val() == '') {
        return;
    }
    if ($('#bank_code').val() == '') {
        return;
    }

    if ($('#account_name').val() == '' || $('#account_name').val() == '' || $('#account_type').val() == '' ) {
        return;
    }
    axios.post('/profile/update/account',{
        bank_account_number: $('#bank_account_number').val(),
        bank_code: $('#bank_code').val(),
        bank_account_name: $("#bank_code option:selected").text(),
        account_name:$('#account_name').val(),
        account_type:$('#account_type').val()
    })
    .then(function(response) {
        var data = response.data
        if (data.status) {
          return  _notification("Account Upated successfully", "success", false);
        }

        if (data.message == 'alien') {
            $(".update_account_number").show()
        }
    });
});

function getTransactionRate(){
    axios.post('/transaction-rating',{
        amount: $('#transfer_amount').val()
    })
    .then(function(response) {
        var data = response.data
        if (response.data.status) {
            $("#transaction_cost").html("Cost of Transaction "+data.data.transaction_cost)
            return;
        }
    });
}

function get_states() {
    var country = $('#country').val();
    axios.get('/onboard/country/state/'+country)
    .then(function(response) {
        var data = response.data
        $('#state').html(data.html);
    });
}

function plot_graph(earning, time){

    var options = {
        series: [{
        name: 'Earnings',
        data: earning,
        fill:['#ffb600']
      }],
        chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: time
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      };
    
    var chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
}

// Menu-list
const sideMenu = document.querySelector("aside");
const menuBtn = document.querySelector("#menu-btn");
const closeBtn = document.querySelector("#close-btn");

menuBtn.addEventListener('click',() => {
    sideMenu.style.display = 'block';
})

closeBtn.addEventListener('click', () => {
    sideMenu.style.display = 'none';
})

// Submenu Slide
let currentScrollPosition = 0;
let scrollAmount = 150;

const sCont = document.querySelector(".storys-container");
const hScroll = document.querySelector(".horizontal-scroll"); 
const btnScrollLeft = document.querySelector("#btn-scroll-left");
const btnScrollRight = document.querySelector("#btn-scroll-right");

btnScrollLeft.style.opacity = "0";

let maxScroll = -sCont.offsetWidth + hScroll.offsetWidth;

function scrollHorizontally(val){
    currentScrollPosition += (val * scrollAmount);

    if(currentScrollPosition >= 0){
        currentScrollPosition = 0
        btnScrollLeft.style.opacity = "0";
    }else{
        btnScrollLeft.style.opacity = "1";
    }

    if(currentScrollPosition <= maxScroll){
        currentScrollPosition = maxScroll;
        btnScrollRight.style.opacity = "0";
    }else{
        btnScrollRight.style.opacity = "1";
    }
    
    sCont.style.left = currentScrollPosition + "px";
}

// Topbar Submenu
let subMenu = document.getElementById("subMenu")

function toggleMenu(){
  subMenu.classList.toggle("open-menu")
}
// Menu-list





